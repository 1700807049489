
//  robotoregular


@font-face {
    font-family: 'robotoregular';
    src: url('../assets/fonts/Roboto-Regular-webfont.eot');
    src: url('../assets/fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Roboto-Regular-webfont.woff') format('woff'), url('../assets/fonts/Roboto-Regular-webfont.ttf') format('truetype'), url('../assets/fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}



//  varela_roundregular


@font-face {
    font-family: 'varela_roundregular';
    src: url('../assets/fonts/VarelaRound-Regular-webfont.eot');
    src: url('../assets/fonts/VarelaRound-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/VarelaRound-Regular-webfont.woff') format('woff'), url('../assets/fonts/VarelaRound-Regular-webfont.ttf') format('truetype'), url('../assets/fonts/VarelaRound-Regular-webfont.svg#varela_roundregular') format('svg');
    font-weight: normal;
    font-style: normal;
}



//  open_sansregular


@font-face {
    font-family: 'open_sansregular';
    src: url('../assets/fonts/OpenSans-Regular-webfont.eot');
    src: url('../assets/fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/OpenSans-Regular-webfont.woff') format('woff'), url('../assets/fonts/OpenSans-Regular-webfont.ttf') format('truetype'), url('../assets/fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}



//  open_sanslight


@font-face {
    font-family: 'open_sanslight';
    src: url('../assets/fonts/OpenSans-Light-webfont.eot');
    src: url('../assets/fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/OpenSans-Light-webfont.woff') format('woff'), url('../assets/fonts/OpenSans-Light-webfont.ttf') format('truetype'), url('../assets/fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}



//  oswald


@import url('https://fonts.googleapis.com/css?family=Oswald');