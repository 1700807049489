
//  Typography and Headings


.icou-brand-title {
    font-family: $font-family-title;
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    font-weight: 400;
}

.icou-brand-title--white {
    color: $white;
}

.icou-sub-title {
    letter-spacing: 0.02rem;
    font-weight: 600;
    letter-spacing: -0.02rem;
}

.icou-sub-title--white {
    color: $white;
}

.icou-lh-s {
    line-height: inherit;
}

.icou-lh-m {
    line-height: 1.5rem;
}

.icou-lh-l {
    line-height: 3rem;
}

.icou-t-padding-m {
    padding: 1rem;
}

.icou-t-padding-l {
    padding: 2.5rem;
}

.icou-t-margin-tb-m {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.icou-t-margin-tb-l {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.section-header {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem;
    text-align: center;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    padding: 1.5rem;
}

.section-header--left-position {
    text-align: left;
}

.section-header--right-position {
    text-align: right;
}

.section-header__section-header-title-center {
    display: inline-block;
    margin-top: 0.7rem;
    margin-bottom: 1.7rem;
}

.section-header__section-header-title-center,
.section-header__section-header-title-left,
.section-header__section-header-title-right {
    position: relative;
}

.section-header__section-header-title-center:before {
    position: absolute;
    content: "";
    top: 8px;
    right: 0;
    width: 22px;
    height: 4px;
    background: $red;
}

.section-header__section-header-title-center:after {
    position: absolute;
    content: "";
    top: 8px;
    left: 0px;
    width: 22px;
    height: 4px;
    background: $red;
}

.section-header__section-header-title-left:before {
    position: absolute;
    content: "";
    top: -2px;
    left: -10px;
    width: 4px;
    height: 22px;
    background: $red;
}

.section-header__section-header-title-left:after {
    position: absolute;
    content: "";
    top: -2px;
    left: -7px;
    width: 22px;
    height: 4px;
    background: $red;
}

.section-header__section-header-title-right:before {
    position: absolute;
    content: "";
    top: -2px;
    right: -10px;
    width: 4px;
    height: 22px;
    background: $red;
}

.section-header__section-header-title-right:after {
    position: absolute;
    content: "";
    top: -2px;
    right: -7px;
    width: 22px;
    height: 4px;
    background: $red;
}



//  Title Line Spacer 


.section-header__separate-line-basement {
    display: block;
    margin-left: 0;
    margin-right: auto;
    border-color: #cacaca;
    border-top-style: dotted;
    border-top-width: 1px;
    margin-top: 1rem;
}