.form-group {
    margin-bottom: 15px;
}

.checkbox,
.checkbox-inline,
.form-group label,
.radio,
.radio-inline {
    color: #919496;
    font-weight: 400;
}

input[type=text] {
    padding-right: 25px;
}

.form-control,
.has-error .form-control,
.has-success .form-control,
.has-warning .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control {
    -webkit-appearance: none;
}

.form-control {
    display: block;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/20…q-.604.603-1.206.603t-1-.396z%22%20fill%3D%22%23919496%22%20/%3E%3C/svg%3E);
    background-position: right 12px center;
    background-repeat: no-repeat;
    width: 100%;
    height: 46px;
    padding: 6px 12px;
    margin-bottom: 0.6rem;
    font-size: 16px;
    line-height: 1.5;
    color: $gray;
    font-family: robotoregular;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9dadc;
    @include border-radius(0);
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .015);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .015);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
    color: #464a4c;
    background-color: #fff;
    border-color: lighten($brand-primary, 10%);
    outline: none;
}

.form-control:focus:hover {
    color: #464a4c;
    background-color: #fff;
    border-color: lighten($brand-primary, 10%);
    outline: none;
}

.form-control:hover {
    color: #464a4c;
    background-color: darken(#fff, 1%);
    border-color: darken(#d9dadc, 5%);
    outline: none;
}

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    padding-right: 48px;
    text-indent: .01px;
    text-overflow: '';
    color: $gray;
}

select.form-control {
    &:not([size]):not([multiple]) {
        $select-border-width: ($border-width * 12.5);
        height: calc(#{$input-height} + #{$select-border-width});
    }
}

.select-icon:after {
    font-family: FontAwesome;
    font-size: 18px;
    top: 11px;
    right: 12px;
    transition: all 0.2s;
    content: "\f107";
    z-index: 5;
    position: absolute;
    background-size: 15px;
    margin-left: auto;
    color: $gray;
    pointer-events: none;
}

.select-icon {
    position: relative;
    display: block;
}

// Newsletter Form
.newsletter-footer-content form {
    position: relative;
}

.newsletter-footer-content input {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    border: 2px solid $abbr-border-color;
    box-shadow: none;
    height: 42px;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
    padding-left: 15px;
    font-style: italic;
    color: #474747;
    border-radius: 0px;
}

.newsletter-footer-content {
    padding: 0;
    input:focus {
        outline: 1 !important;
        outline-color: $abbr-border-color !important;
        -webkit-appearance: none;
        background: transparent;
        border: 2px solid $abbr-border-color !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
}

.newsletter-footer-content button {
    position: absolute;
    right: 0;
    top: 0;
    background: $abbr-border-color;
    height: 100%;
    font-size: 13px;
    color: #fff;
    font-weight: bold;
    border: 0px solid;
    border-radius: 0px;
    outline: none;
    box-shadow: none;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.newsletter-footer-content form:hover input {
    border: 2px solid $brand-primary !important;
    background: transparent;
    outline: 1 !important;
    outline-color: #e96656 !important;
    -webkit-appearance: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.newsletter-footer-content form:hover button {
    background: $brand-primary;
}