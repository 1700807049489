// Home Area
.portfolio-section-home-area {
    margin-top: -6rem;
    margin-bottom: 2rem;
}

.portfolio-section-home-area .services-single-content {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.portfolio-section-home-area .works {
    padding-bottom: 100px;
    background: #FFFFFF;
    min-height: 800px;
}

// GRID 
.portfolio-section-home-area .portfolio-grid {
    margin: auto;
    padding: 0;
    list-style: none;
    position: relative;
    width: 100%;
}

.portfolio-section-home-area .portfolio-grid li {
    position: relative;
    float: left;
    overflow: hidden;
    border: 5px solid $white;
    width: 25%;
    /* Fallback */
    width: -webkit-calc(100% / 4);
    width: calc(100% / 4);
    -webkit-transition: 0.4s all linear;
    transition: 0.4s all linear;
}

.portfolio-section-home-area .portfolio-grid figure,
.portfolio-section-home-area .portfolio-grid figure img {
    display: block;
    max-width: 100%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 0, 300px);
    transform: translate3d(0, 0, 300px);
    background: $white;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    cursor: pointer;
}

// Page Area
#portfolio-section {}

.portfolio-section-area {
    position: relative;
}

.portfolio-section-area .portfolio-filter-wrapper,
.portfolio-gallery-section .portfolio-filter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.portfolio-section-area .section-header,
.portfolio-gallery-section .section-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 0;
    padding-right: 1.2rem;
    padding-left: 0;
}

.portfolio-section-area .portfolio-filter,
.portfolio-gallery-section .portfolio-filter {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0;
}

.portfolio-section-area .portfolio-filter ul li,
.portfolio-gallery-section .portfolio-filter ul li {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.portfolio-section-area .filter li,
.portfolio-gallery-section .filter li {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
}

.portfolio-section-area .portfolio-filter li span,
.portfolio-gallery-section .portfolio-filter li span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 11px;
    background: transparent;
    color: #444444;
    padding: 12px 18px 12px 18px;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #cacaca;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.portfolio-section-area .portfolio-filter li span:hover,
.portfolio-gallery-section .portfolio-filter li span:hover {
    background: $link-hover-color;
    color: $white;
    border-color: $brand-primary;
}

.portfolio-section-area .portfolio-filter li span:active,
.portfolio-gallery-section .portfolio-filter li span:active {
    background: $brand-primary;
}

.portfolio-section-area .portfolio-filter li.active:after,
.portfolio-gallery-section .portfolio-filter li.active:after {
    content: "";
    position: absolute;
    width: 10px;
    top: 6;
    left: 50%;
    margin-left: -2px;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: $brand-primary transparent transparent transparent;

}

.portfolio-section-area .portfolio-filter li.active>span,
.portfolio-gallery-section .portfolio-filter li.active>span {
    background: $brand-primary;
    border-color: $brand-primary;
    cursor: default;
    color: $white;
}

.portfolio-section-area .card {
    @include border-radius(0px);
    margin-bottom: 5rem;
    border: 0;
    -webkit-box-shadow: 0px 13px 30px -12px rgba(0, 0, 0, 0.30);
    -moz-box-shadow: 0px 13px 30px -12px rgba(0, 0, 0, 0.30);
    box-shadow: 0px 13px 30px -12px rgba(0, 0, 0, 0.30);
    -webkit-transition: 0.4s all linear;
    transition: 0.4s all linear;
}

.portfolio-section-area .portfolio-item .card-img-top {
    position: relative;
}

.portfolio-section-area .portfolio-item img {
    width: 100%;
    @include border-radius(0px);
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    cursor: pointer;
    -webkit-transition: 0.4s all linear;
    transition: 0.4s all linear;
}

.portfolio-section-area .card-block {
    padding: 30px;
}

.portfolio-section-area .card-bottom-section {
    border-bottom: 7px solid $brand-primary;
    text-align: center;
    background-color: $gray-lightest;
}

.portfolio-section-area .compliment-text {
    margin-bottom: 0;
    color: $gray-light;
}

// Portfolio Gallery
#portfolio-section .portfolio-gallery-section {}

.portfolio-gallery-section .portfolio-grid {}

.portfolio-gallery-section .portfolio-item {
    margin-bottom: 30px;
}

.portfolio-gallery-section .portfolio-grid {
    margin: auto;
    padding: 0;
    list-style: none;
    position: relative;
    width: 100%;
}

.portfolio-gallery-section .portfolio-grid figure {
    position: relative;
    overflow: hidden;
    -webkit-transition: 0.4s all linear;
    transition: 0.4s all linear;
}

.portfolio-gallery-section .portfolio-grid figure,
.portfolio-gallery-section .portfolio-grid figure img {
    display: block;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 0, 300px);
    transform: translate3d(0, 0, 300px);
    background: $white;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    cursor: pointer;
}

// 3 Coloumn and 4 Column li img
.portfolio-section-area .grid figure {
    position: relative;
    overflow: hidden;
    -webkit-transition: 0.4s all linear;
    transition: 0.4s all linear;
}

.portfolio-section-area .grid figure,
.portfolio-section-area .grid figure img {
    display: block;
    max-width: 100%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 0, 300px);
    transform: translate3d(0, 0, 300px);
    background: $white;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    cursor: pointer;
}

//figure
figure.portfolio-item {
    position: relative;
    margin: 0;
}

figure.portfolio-item img {
    display: block;
    max-width: 100%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 0, 300px);
    transform: translate3d(0, 0, 300px);
    background: $white;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    cursor: pointer;
}

figure.portfolio-item:hover img, {
    opacity: 0.3;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

figure.portfolio-item h2 {
    font-size: 158.75%;
}

figure.portfolio-item h2,
figure.portfolio-item p {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
}

figure.portfolio-item figcaption::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 160px;
    height: 160px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 1);
    transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 1);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
}

figure.portfolio-item p {
    width: 100px;
    text-align: center;
    text-transform: none;
    font-size: 121%;
    line-height: 2;
}

figure.portfolio-item p a {
    color: #fff;
    cursor: pointer;
    -webkit-transition: all 200ms linear 0ms;
    -moz-transition: all 200ms linear 0ms;
    -ms-transition: all 200ms linear 0ms;
    transition: all 200ms linear 0ms;
}

figure.portfolio-item p a:hover,
figure.portfolio-item p a:focus {
    color: #ffffff;
}

figure.portfolio-item p a i {
    display: inline-block;
    font-weight: 300;
    color: $gray-dark;
    opacity: 0;
    margin-right: 12px;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.portfolio-item p a i::before {
    background: #ffffff;
    position: relative;
    width: 50px;
    height: 50px;
    @include border-radius(50%);
    text-align: center;
    line-height: 15px;
    vertical-align: middle;
    padding: 10px 10px 10px 10px;
    -webkit-transition: all 200ms linear 0ms;
    -moz-transition: all 200ms linear 0ms;
    -ms-transition: all 200ms linear 0ms;
    transition: all 200ms linear 0ms;
}

figure.portfolio-item p a i:hover::before {
    background: $red;
    color: $white;
}

figure.portfolio-item p a:first-child i {
    -webkit-transform: translate3d(-60px, -60px, 0);
    transform: translate3d(-60px, -60px, 0);
}

figure.portfolio-item p a:nth-child(2) i {
    -webkit-transform: translate3d(60px, -60px, 0);
    transform: translate3d(60px, -60px, 0);
}

figure.portfolio-item p a:nth-child(3) i {
    -webkit-transform: translate3d(-60px, 60px, 0);
    transform: translate3d(-60px, 60px, 0);
}

figure.portfolio-item p a:nth-child(4) i {
    -webkit-transform: translate3d(60px, 60px, 0);
    transform: translate3d(60px, 60px, 0);
}

figure.portfolio-item:hover figcaption::before {
    opacity: 1;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
    transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
}

figure.portfolio-item:hover h2 {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1);
    transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1);
}

figure.portfolio-item:hover p i:empty {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    /* just because it's stronger than nth-child */
    opacity: 1;
}

// Portfolio Single
.portfolio-single-section {}

.portfolio-single-section .section-header {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    padding-left: 0.2rem;
    padding-right: 0.1rem;
}

.portfolio-single-image-wrapper {
    position: relative;
    display: block;
}

.portfolio-image-holder {
    background-size: cover;
}

.portfolio-image-holder img {
    max-width: 100%;
    height: auto;
}

.portfolio-single-info .widget {
    position: relative;
    background: #f9f9f9;
    border: 1px solid $gray-lighter;
    margin-top: 0.3rem;
    margin-bottom: 3rem;
    margin-left: 1rem;
    padding: 2rem;
}

.portfolio-single-info .widget p {
    color: $gray-dark;
    margin-bottom: 1.35rem;
}

.portfolio-single-info .widget p:last-child {
    margin-bottom: 0;
}

.portfolio-single-info .widget:before {
    position: absolute;
    content: "";
    top: -4px;
    left: -4px;
    width: 4px;
    height: 35px;
    background: $red;;
}

.portfolio-single-info .widget:after {
    position: absolute;
    content: "";
    top: -4px;
    left: 0;
    width: 35px;
    height: 4px;
    background: $red;;
}

.portfolio-single-dots {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.portfolio-single-section .portfolio-single-image-wrapper .share-button {
    background: lighten($red,3%);
    position: absolute;
    display: inline-table;
    cursor: pointer;
    left: 2.5rem;
    top: -0.5rem;
    width: 25px;
    height: 25px;
    color: $white;
    @include border-radius(0 0 50% 50%);
    text-align: center;
    line-height: 10px;
    vertical-align: middle;
    padding: 16px;
    -webkit-transition: all 200ms linear 0ms;
    -moz-transition: all 200ms linear 0ms;
    -ms-transition: all 200ms linear 0ms;
    transition: all 200ms linear 0ms;
    z-index: 1;
}
.portfolio-single-section .portfolio-single-image-wrapper .share-button:hover {
    background: $red;
}
.portfolio-single-section .portfolio-single-image-wrapper .share-button::after {
    position: absolute;
    content: "";
    top: 0px;
    left: 45px;
    width: 0;
    height: 0;
    border-top: 0;
    border-left: 0px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 7px solid darken($red, 25%);
}

.portfolio-single-section .portfolio-single-image-wrapper .slick-prev {
    width: 52px;
    height: 52px;
    left: 0px;
    opacity: 0.8;
    top: 45%;
    background-color: $red;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.portfolio-single-section .portfolio-single-image-wrapper .slick-next {
    width: 52px;
    height: 52px;
    right: 0px;
    opacity: 0.8;
    top: 45%;
    background-color: $red;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.portfolio-single-section .slick-prev:focus:hover,
.portfolio-single-section .slick-prev:hover {
    background-color: $red;
    opacity: 1;
}

.portfolio-single-section .slick-next:focus:hover,
.portfolio-single-section .slick-next:hover {
    background-color: $red;
    opacity: 1;
}

.portfolio-single-section .slick-prev:before,
.slick-next:before {
    font-family: 'FontAwesome';
    display: block;
    background-color: transparent;
    border-radius: 0px;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.portfolio-single-section .slick-prev:before {
    font-size: 14px;
    content: $fa-var-chevron-left;
    color: $white;
}

.portfolio-single-section .slick-next:before {
    font-size: 14px;
    content: $fa-var-chevron-right;
    color: $white;
}

// Slick Dots Portfolio Single

.portfolio-single-section .slick-dots li button::before {
    font-family: 'FontAwesome';
    font-size: 15px;
    content: "\f111";
    -webkit-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.portfolio-single-section .slick-dots li button::before {
    font-family: 'FontAwesome';
    font-size: 15px;
    content: "\f111";
    opacity: .7;
    color: $white;
    -webkit-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.portfolio-single-section .slick-dots li.slick-active button:before {
    font-family: 'FontAwesome';
    font-size: 15px;
    opacity: .8;
    color: $red;
    content: "\f111";
}