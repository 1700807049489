.testimonial-section {}

.testimonial-card {
    min-height: 260px;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    position: relative;
    border-color: lighten($gray-light, 45%);
    @include border-radius(0);
    @include BoxShadowHelper(3);
}

.testimonial-section .section-header {
    margin-bottom: 1rem;
}

.testimonial-card::before {
    position: absolute;
    content: "";
    top: -2px;
    left: -4px;
    width: 4px;
    height: 32px;
    background: $red;
}

.testimonial-card::after {
    position: absolute;
    content: "";
    top: -2px;
    left: -3px;
    width: 32px;
    height: 4px;
    background: $red;
}

.testimonial-card .testimonial-quote-wrapper {
    padding-top: 1.25rem;
    padding-bottom: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.testimonial-card p.testimonial-quote-text {
    font-style: italic;
    font-weight: 600;
}

.testimonial-card p.testimonial-quote-text::before {
    content: "\f10d";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    color: $gray-lighter;
    font-size: 30px;
    position: absolute;
    top: 35px;
    left: 28px;
    z-index: 0;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.testimonial-card .testimonial-person-title {
    margin-bottom: 0;
}

.testimonial-card .testimonial-company-name {
    margin-bottom: 0;
    color: darken(#cacaca, 10%);
}

.testimonial-card .testimonial-img-circle {
    border: 7px solid #cacaca;
    border-radius: 50%;
    display: block;
    height: 100px;
    width: 100px;
    margin: -40px auto 0;
    -webkit-transition: border-color 0.4s ease 0s;
    transition: border-color 0.4s ease 0s;
    overflow: hidden;
}

.testimonial-card .testimonial-dots {
    position: relative;
}

.testimonial-card .testimonial-footer {
    text-align: left;
    background: #f9f9f9;
    border-top: 1px solid lighten($gray-light, 49%);
}