
//  Icons Round


.box>.icon {
    text-align: center;
    position: relative;
}

.box>.icon>.image {
    position: relative;
    z-index: 2;
    margin: auto;
    width: 88px;
    height: 88px;
    border: 8px solid white;
    line-height: 88px;
    border-radius: 50%;
    background: #a21e27;
    vertical-align: middle;
}

.box>.icon:hover>.image {
    background: #333;
}

.box>.icon>.image>i {
    font-size: 36px !important;
    color: #fff !important;
}

.box>.icon:hover>.image>i {
    color: white !important;
}

.box>.icon>.info {
    margin-top: -24px;
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid #e0e0e0;
    padding: 15px 0 10px 0;
}

.box>.icon:hover>.info {
    background: rgba(0, 0, 0, 0.04);
    border-color: #e0e0e0;
    color: white;
}

.box>.icon>.info>h3.title {
    font-family: "Roboto", sans-serif !important;
    font-size: 16px;
    color: #222;
    font-weight: 500;
}

.box>.icon>.info>p {
    font-family: "Roboto", sans-serif !important;
    font-size: 13px;
    color: #666;
    line-height: 1.5em;
    margin: 20px;
}

.box>.icon:hover>.info>h3.title,
.box>.icon:hover>.info>p,
.box>.icon:hover>.info>.more>a {
    color: #222;
}

.box>.icon>.info>.more a {
    font-family: "Roboto", sans-serif !important;
    font-size: 12px;
    color: #222;
    line-height: 12px;
    text-transform: uppercase;
    text-decoration: none;
}

.box>.icon:hover>.info>.more>a {
    color: #fff;
    padding: 6px 8px;
    background-color: #63B76C;
}

.box .space {
    height: 30px;
}


//  Icon Text 


.icon-text {
    z-index: 1;
    display: block;
    position: relative;
    height: 100%;
}

.icon-text .icon-feature {
    padding-top: 35px;
    padding-bottom: 35px;
}

.icon-text .icon-feature-icon {
    font-size: 55px;
    float: left;
    margin-top: 2px;
    margin-right: 25px;
    color: $red;
}

.icon-text .icon-feature-icon i {
    min-width: 72px;
}

@media (max-width: 1024px) {
    .icon-text .icon-feature-icon {
        float: none;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .icon-text .icon-feature {
        margin-bottom: 55px;
        text-align: center;
    }
}