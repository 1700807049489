
.services-home {
    z-index: 1;
    display: block;
    position: relative;
    height: 100%;
}

.services-home .icon-feature {
    margin-bottom: 55px;
}

.services-home .icon-feature-icon {
    font-size: 55px;
    float: left;
    margin-top: 2px;
    margin-right: 25px;
    color: $brand-primary;
}

.services-home .icon-feature-icon i {
    min-width: 72px;
}

.services-home .section-header p {
    color: #323c46;
}

.services-home .teaser-text-container .icou-brand-title {
    color: $gray-dark;
}

@media (max-width: 1024px) {
    .services-home .icon-feature-icon {
        float: none;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .services-home .icon-feature {
        margin-bottom: 55px;
        text-align: center;
    }
}



//  Services Teaser Section


.teaser-box {
    background-color: #f6f9fa;
    border: none;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 1rem;
    @include BoxShadowHelper(3);
    -webkit-transition: 0.4s all linear;
    transition: 0.4s all linear;
}

.teaser-box::before {
    position: absolute;
    content: "";
    top: -4px;
    right: -4px;
    width: 4px;
    height: 35px;
    background: #d9534f;
}

.teaser-box::after {
    position: absolute;
    content: "";
    top: -4px;
    right: 0;
    width: 35px;
    height: 4px;
    background: #d9534f;
}

.teaser-box .expression {
    display: block;
    margin-left: 0;
    margin-right: auto;
    border-color: #d8383b;
    border-top-style: solid;
    border-top-width: 2px;
    margin-top: 11px;
    margin-bottom: 2px;
    width: 40px;
}

.teaser-box .teaser-image-container {
    background-color: $brand-primary;
    background-repeat: no-repeat;
    overflow: hidden;
    width: 100%;
    display: block;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.teaser-text-container {
    text-align: left;
    padding: 10px 10px 2px 20px;
    overflow: hidden;
}

.teaser-title {
    padding-top: 20px;
    margin: 0;
}

.teaser-text {
    padding: 10px 10px 10px 0;
    margin-bottom: 0;
}

figure.teaser {
    margin: 0;
}

figure.teaser {
    display: block;
    max-width: 100%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 0, 300px);
    transform: translate3d(0, 0, 300px);
    background: $white;
    -webkit-perspective: 1700px;
    perspective: 1700px;
    cursor: pointer;
}

figure.teaser img {
    min-height: 160px;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 0, 300px);
    transform: translate3d(0, 0, 300px);
}

figure.teaser:hover img {
    min-height: 160px;
    opacity: 0.3;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

figure.teaser h2 {
    font-size: 158.75%;
}

figure.teaser h2,
figure.teaser p {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
}

figure.teaser figcaption::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 160px;
    height: 160px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 1);
    transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 1);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
}

figure.teaser p {
    width: auto;
    text-transform: none;
    font-size: 121%;
    line-height: 2;
}

figure.teaser p a {
    color: #fff;
}

figure.teaserp a:hover,
figure.teaser p a:focus {
    opacity: 0.6;
}

figure.teaser p a i:hover::before {
    background: $brand-primary;
    color: #ffffff;
}

figure.teaser p a i {
    display: inline-block;
    font-weight: 300;
    color: $gray-dark;
    opacity: 0;
    margin-right: 12px;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.teaser p a i::before {
    background: #ffffff;
    position: relative;
    width: 50px;
    height: 50px;
    @include border-radius(50%);
    text-align: center;
    line-height: 20px;
    vertical-align: middle;
    padding: 10px 10px 10px 10px;
    -webkit-transition: all 200ms linear 0ms;
    -moz-transition: all 200ms linear 0ms;
    -ms-transition: all 200ms linear 0ms;
    transition: all 200ms linear 0ms;
}

figure.teaser p a:first-child i {
    -webkit-transform: translate3d(-60px, -60px, 0);
    transform: translate3d(-60px, -60px, 0);
}

figure.teaser p a:nth-child(2) i {
    -webkit-transform: translate3d(60px, -60px, 0);
    transform: translate3d(60px, -60px, 0);
}

figure.teaser p a:nth-child(3) i {
    -webkit-transform: translate3d(-60px, 60px, 0);
    transform: translate3d(-60px, 60px, 0);
}

figure.teaser p a:nth-child(4) i {
    -webkit-transform: translate3d(60px, 60px, 0);
    transform: translate3d(60px, 60px, 0);
}

figure.teaser:hover figcaption::before {
    opacity: 1;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
    transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
}

figure.teaser:hover h2 {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1);
    transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1);
}

figure.teaser:hover p i:empty {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    /* just because it's stronger than nth-child */
    opacity: 1;
}



//  Services Single Pages


#services-single-area {
    position: relative;
}

.service-section {
    position: relative;
}

.service-section-container {
    position: relative;
}

.service-section .section-header {
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 1.6rem;
}

.services-text-feature {
    position: relative;
    margin-top: 0;
    margin-bottom: 30px;
    padding: 30px 15px 30px 15px;
    background: #fff;
}

.service-section .service-box {
    margin-bottom: 40px;
}

.service-section .service-section-container .service-box .service-content>a {
    color: $gray-dark;
    font-weight: 400;
    font-family: $font-family-title;
    -webkit-transition: all 200ms linear 0ms;
    -moz-transition: all 200ms linear 0ms;
    -ms-transition: all 200ms linear 0ms;
    transition: all 200ms linear 0ms;
}

.service-section .service-section-container .service-box .service-content>a:hover {
    color: $brand-primary;
}

.service-section .service-sidebar .widget {
    background: transparent;
    margin-bottom: 0.8rem;
    padding: 0;
}

.service-section .service-sidebar ul.sub-page-list {
    margin: 0;
    padding: 0;
}

.service-section .service-sidebar ul.sub-page-list li {
    list-style: none;
    border: 1px solid lighten(#cacaca, 5%);
    background: transparent;
    margin-bottom: 0.6rem;
    line-height: 31px;
    -webkit-transition: all 200ms linear 0ms;
    -moz-transition: all 200ms linear 0ms;
    -ms-transition: all 200ms linear 0ms;
    transition: all 200ms linear 0ms;
}

.service-section .service-sidebar ul.sub-page-list li.current {
    border: 1px solid $red;
}

.service-section .service-sidebar ul.sub-page-list li>a {
    display: block;
    text-decoration: none;
    color: #333333;
    font-size: 12px;
    font-weight: 600;
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    padding: 0.8rem;
}

.service-section .service-sidebar ul.sub-page-list li:hover {
    background-color: $brand-primary;
    color: $white;
    border-color: $brand-primary;
    cursor: pointer;
}

.service-section .service-sidebar ul.sub-page-list li:hover>a {
    color: $white;
}

.service-section .service-sidebar ul.sub-page-list>li.current>a {
    color: $white;
    background: $red;
    border: none;
    opacity: 1;
}

.service-section .service-sidebar ul.sub-page-list>li.current>a::before {
    content: $fa-var-caret-right;
    font-family: FontAwesome;
    position: relative;
    font-size: 14px;
    z-index: 1000;
    margin-left: 5px;
    z-index: 2;
    right: 7px;
    top: 0;
    color: inherit;
}

.service-section .service-sidebar .brochure-widget {
    background: #f7f7f9;
    padding: 1.4rem;
    border: 1px solid lighten(#cacaca, 5%);
}

.service-section .service-sidebar ul.download-brochure-list li a:hover {
    background: lighten($brand-primary,25%);
}

.service-section .services-media {
    position: relative;
}

.service-section .videoembed {
    width: 100%;
    height: 500px;
    border: 0;
}

.service-section .image-row {}

.service-section .image-row img {
    width: 100%;
    margin-bottom: 1rem;
}



//  Services Content Area


#services-single-area {
    position: relative;
}

.service-section .sidebar {
    position: relative;
}

.service-section .sidebar .single-sidebar-item {
    margin-bottom: 50px;
}

.service-section .sidebar .single-sidebar-item .solution-categories {}

.service-section .sidebar .single-sidebar-item .solution-categories li {}

.service-section .sidebar .single-sidebar-item .solution-categories li a {
    background: #f7f7f7 none repeat scroll 0 0;
    color: #292929;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 55px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 30px;
    padding-right: 20px;
    transition: all 500ms ease;
    position: relative;
}

.service-section .sidebar .single-sidebar-item .solution-categories li a:before {
    position: absolute;
    top: 12px;
    left: 0;
    width: 0px;
    height: 30px;
    background: #01305e;
    content: "";
    transition: all 500ms ease;
}

.sec-title-two .border {
    background: #e7e7e7 none repeat scroll 0 0;
    display: block;
    height: 1px;
    width: 100%;
}

.service-section .sidebar .single-sidebar-item .brochures-list li a {
    background: #f7f7f7 none repeat scroll 0 0;
    color: #292929;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 65px;
    padding-right: 20px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    transition: all 500ms ease;
}

.service-section .sidebar .single-sidebar-item .brochures-list li a span::before {
    background: #292929 none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    height: 45px;
    line-height: 45px;
    margin-left: -2px;
    text-align: center;
    width: 50px;
    transition: all 500ms ease;
    margin-right: 15px;
    position: relative;
    top: 1px;
}

.service-section .sidebar .single-sidebar-item .brochures-list li a i {
    margin: 26px 0;
}

.service-section .sidebar .single-sidebar-item .brochures-list li a:hover {
    background: #fdc716;
    color: #fff;
}

.service-section .sidebar .single-sidebar-item .brochures-list li a:hover span::before {
    background: #01305e;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item {}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item {}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .text {
    background: #01305e none repeat scroll 0 0;
    color: #ffffff;
    border-radius: 5px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 40px;
    padding-right: 10px;
    position: relative;
    margin-bottom: 30px;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .text::before {
    background: rgba(0, 0, 0, 0) url("../img/solution-single/bottom-arrow.png") repeat scroll 0 0;
    bottom: -14px;
    content: "";
    height: 14px;
    left: 30px;
    position: absolute;
    width: 15px;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .text .arrow {
    background: rgba(0, 0, 0, 0) url("../img/solution-single/testimonial-icon-bg.png") no-repeat scroll 0 0;
    height: 70px;
    left: 0;
    padding: 10px 7px;
    position: absolute;
    top: 0;
    width: 70px;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .text .arrow i {
    font-size: 25px;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .text p {
    font-size: 16px;
    line-height: 32px;
    margin: 0;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .client-info .ing-box {
    width: 75px;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .client-info .ing-box,
.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .client-info .text-box {
    display: table-cell;
    vertical-align: middle;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .client-info .text-box {
    padding-left: 10px;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .client-info .text-box h4 {
    margin: 0 0 3px;
    text-transform: capitalize;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .client-info .text-box span {
    color: #01305e;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.service-section .sidebar .single-sidebar-item .client-testimonial-item .single-client-testimonial-item .client-info .text-box .review-box ul li {
    color: #fdc716;
    display: inline-block;
    margin-right: 3px;
}

.service-section .services-single-content .top {
    margin-bottom: 10px;
    overflow: hidden;
}

.service-section .services-single-content .top .img-holder {
    margin-right: -25px;
}

.service-section .services-single-content .top .text-holder {
    padding-left: 25px;
}

.service-section .services-single-content .top .text-holder .sec-title-two {
    padding-bottom: 34px;
}

.service-section .services-single-content .top .text-holder h3 {
    margin: 20px 0 0;
    text-transform: capitalize;
}

.service-section .services-single-content .services-bottom {
    overflow: hidden;
}

.service-section .services-single-content .services-bottom .img-holder {
    margin-right: -25px;
}

.service-section .services-single-content .services-bottom .text-holder {
    padding-left: 25px;
}

.service-section .services-single-content .services-bottom .accordion-box {
    margin: 0;
    padding-left: 25px;
}

.service-section .services-single-content .experts-provide {
    padding-top: 70px;
    padding-left: 25px;
}

.service-section .services-single-content .experts-provide .sec-title-two {
    padding-left: 15px;
    padding-bottom: 34px;
}

.service-section .services-single-content .experts-provide .single-experts-provide {
    padding-bottom: 60px;
    position: relative;
}

.service-section .services-single-content .experts-provide .single-experts-provide .icon-holder {
    width: 70px;
}

.service-section .services-single-content .experts-provide .single-experts-provide .icon-holder,
.service-section .services-single-content .experts-provide .single-experts-provide .text-holder {
    display: table-cell;
    vertical-align: middle;
}

.service-section .services-single-content .experts-provide .single-experts-provide .text-holder {
    padding-left: 30px;
    padding-right: 30px;
}

.service-section .services-single-content .experts-provide .single-experts-provide .icon-holder :before {
    display: block;
    background: $brand-primary none repeat scroll 0 0; //border: 5px #fff solid;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    height: 60px;
    line-height: 60px;
    text-align: center;
    width: 60px;
    @include border-radius(0px);
}

.service-section .services-single-content .experts-provide .single-experts-provide .text-holder h3 {
    margin: 0 0 8px;
}

.service-section .services-single-content .experts-provide .single-experts-provide .text-holder p {
    margin: 0;
}

.service-section .services-single-content .caption-box {
    overflow: hidden; //background: url(../img/solution-single/caption-bg.jpg);
    background: $abbr-border-color;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 40px;
}

.service-section .services-single-content .caption-box .title h3 {
    color: #fff;
    line-height: 32px;
    margin: 0;
    text-transform: capitalize;
}

.service-section .services-single-content .caption-box .button a {
    margin: 8px 0;
    padding: 10px 30px;
}