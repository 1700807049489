
//  Homepage Slider


.section.banner {
    padding: 0 0;
}

#slides .slide-caption {
    padding: 10px 20px;
    position: relative;
    text-align: left;
    max-width: 100%;
    z-index: 3;
    margin-top: 20%;
}

.banner a {
    text-decoration: none;
}

.banner h2 {
    min-width: 470px;
    width: 520px;
    border-style: none;
    border-color: transparent;
    background-color: transparent;
    color: #fff;
    display: block;
    padding: 15px 0;
    font-size: 35px;
    font-family: $font-family-title;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    text-decoration: none;
    line-height: 30px;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.banner h2:hover {
    color: $red;
}

.banner p {
    min-width: 470px;
    border-style: none;
    display: inline-block;
    border-color: transparent;
    background-color: transparent;
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    padding: 15px 0;
    float: left;
    width: 610px;
    margin: 0;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.slides-pagination a {
    border: 0;
    width: 11px;
    height: 11px;
    background: #fff;
    text-indent: -200%;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    margin-right: 3px;
}

.slides-pagination a.current {
    background: $brand-primary;
}

.slides-pagination {
    margin-bottom: 95px;
}

.slider-button-wrapper {
    padding: 30px 20px;
}

.slider-button-wrapper .icou-button {
    margin-right: 0.8rem;
}

.slider-button-wrapper .icou-button:last-child {
    margin-right: 0;
}

.slides-navigation .prev {
    display: flex;
    align-items: center;
    justify-content: center;;
    width: 52px;
    height: 52px;
    left: 18px;
    top: 45%;
    background-color: rgba(215, 215, 215, 0.46);
    transition: all 300ms linear 0ms;
}

.slides-navigation .prev:hover,
.slides-navigation .next:hover {
    background-color: $brand-primary;
}

.slides-navigation .next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    right: 19px;
    top: 45%;
    background-color: rgba(215, 215, 215, 0.46);
    transition: all 300ms linear 0ms;
}

.slides-navigation::before, 
.slides-navigation::after {
    position: relative;
    font-family: FontAwesome;
    background-color: transparent;
    width: 35px;
    padding-left: 5px;
    height: 35px;
    vertical-align: middle;
    color: white;
    -webkit-font-smoothing: antialiased;
    border-radius: 0px;
    transition: all 300ms linear 0ms;
}

.slides-navigation .prev::before {
    font-family: FontAwesome;
    content: $fa-var-chevron-left;
    color: $white;
}
.slides-navigation .next::after {
    font-family: FontAwesome;
    content: $fa-var-chevron-right;
    color: $white;
}


//  Intro Area


#feature-intro .intro-tables {
    top: -130px;
    position: relative;
}

.intro-tables .intro-table {
    background: $white;
    position: relative;
    text-align: center;
    width: 100%;
    min-height: 280px;
    margin: 0;
    @include BoxShadowHelper(1);
    -webkit-transition: 0.4s all linear;
    transition: 0.4s all linear;
    z-index: 2;
}

.intro-tables .img-holder {
    position: relative;
    overflow: hidden;
}

.intro-tables .img-holder img {
    max-width: 100%;
    border: 0;
}

.intro-tables .intro-table-footer {
    padding: 1.2rem;
}

.intro-tables .intro-table>.intro-table-wrapper {
    margin-top: 0;
}

.intro-tables .intro-table>.intro-table-wrapper>p {
    color: inherit;
    line-height: 24px;
    margin-top: 1rem;
    margin-bottom: 1.3rem;
}

.intro-tables .intro-table>.intro-table-wrapper>i {
    display: block;
    padding: 15px;
    line-height: 44px;
    color: $brand-primary;
    font-size: 32px;
    padding: 1.2rem;
}