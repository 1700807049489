#main-footer {
    background: $gray-dark;
    color: white;
    padding: 5em 0;
    font-size: 1rem;
}

#main-footer a {
    color: $gray-light;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
    text-decoration: none;
}

#main-footer a:hover {
    color: $link-hover-color;
}

.footer-wrapper {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
}

.footer-col {
    position: relative;
}

.footer-col-title {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 2rem;
}

.about-footer {
    position: relative;
    padding: 0;
}

.services-footer {
    position: relative;
    padding: 0;
}

.latest-posts-footer {
    position: relative;
    padding: 0 10px 0 10px;
}

.latest-post-details {
    padding-left: 65px;
    position: relative;
}

.latest-post-details i {
    font-size: 18px;
    height: 49px;
    width: 49px;
    background-color: $abbr-border-color;
    line-height: 49px;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 2px;
    left: 0;
    @include border-radius(0px);
}

.latest-post-details h2 {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 8px;
    color: #cecece;
}

.latest-post-details h2 a {
    color: #cecece;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.latest-post-details h2 a:hover {
    color: #1bb580;
}

.latest-post-details p {
    font-size: 15px;
    margin-bottom: 0.6rem;
    color: $gray-lightest;
}

.latest-post-details .footer-link p {
    border-bottom: 1px solid;
    border-bottom-color: #2b2b2b;
}

.latest-post-details-no-border p {
    border-bottom: 0px solid;
    color: $gray-lightest;
}

.bottom {
    background-color: darken($gray-dark, 2%);
    color: #7f7f7f;
    border-top: 1px solid #2b2b2b;
    border-bottom: 7px solid $brand-primary;
    text-align: center;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
}

.bottom-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.bottom-left {
    vertical-align: middle;
    padding: 0;
}

.bottom-right {
    vertical-align: middle;
    padding: 0;
}

.bottom-wrapper .social-footer {
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 0;
}

.bottom-wrapper .copyright {
    padding: 1rem;
}

.bottom-wrapper p {
    font-size: 0.9rem;
    margin-bottom: 0;
}

.bottom-wrapper .social-footer ul.social-share li a {
    width: 35px;
    height: 35px;
    line-height: 34px;
    background-color: $abbr-border-color;
    @include border-radius(0px);
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.bottom-wrapper .social-footer ul.social-share li a:hover {
    background: $brand-primary;
}